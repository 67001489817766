import * as React from "react"
import Layout from "../components/Layout"
// import Content from "../components/Content"
import { graphql } from "gatsby"
import ProjectList from "../components/ProjectList"
import { useState } from "react"
import Intro from "../components/Intro"

// markup
const WorkPage = ({ data }) => {
  const [selectedTag, setSelectedTag] = useState("all")
  const projects = data.contentfulSeite.content.filter(
    (content) => content.__typename === "ContentfulContentProjektListe"
  )[0].projects
  // eslint-disable-next-line no-undef
  const tags = [...new Set(projects.map((project) => project.tag.title))]

  return (
    <Layout title="Work">
      <Intro data={{ headline: "Work" }} smallHeadline>
        <ul className="mb-8 md:mb-14">
          <li className="inline text-xl lg:text-2xl">
            <button
              onClick={() => {
                setSelectedTag("all")
              }}
              disabled={selectedTag === "all"}
              className={`${
                selectedTag === "all" ? "text-red font-bold" : ""
              } focus:outline-none`}
            >
              <span className="sr-only">Show</span>
              All
              <span className="sr-only">projects</span>
            </button>
          </li>
          <span aria-hidden className="text-xl lg:text-2xl">
            {" "}
            /{" "}
          </span>
          {tags.map((tag, i) => (
            <li className="inline text-xl lg:text-2xl" key={i}>
              <button
                onClick={() => {
                  setSelectedTag(tag)
                }}
                disabled={selectedTag === tag}
                className={`${
                  selectedTag === tag ? "text-red font-bold" : ""
                } focus:outline-none`}
              >
                <span className="sr-only">Only show projects with label</span>
                {tag}
              </button>
              {i !== tags.length - 1 && (
                <span aria-hidden className="text-xl lg:text-2xl">
                  {" "}
                  /{" "}
                </span>
              )}
            </li>
          ))}
        </ul>
      </Intro>

      <ProjectList
        data={
          selectedTag === "all"
            ? projects
            : projects.filter((project) => project.tag.title === selectedTag)
        }
      />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulSeite(slug: { eq: "work" }) {
      content {
        __typename
        ... on ContentfulContentProjektListe {
          projects {
            title
            slug
            shortDescription
            mainImage {
              file {
                url
              }
              title
              gatsbyImageData(
                width: 800
                height: 420
                quality: 90
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
            tag: tags {
              title
            }
          }
        }
      }
    }
  }
`

export default WorkPage
